export default {
  sv: {
    placeholder: {
      input: {
        ssn: 'Ange personnummer',
      },
    },
    button: {
      start: 'Starta',
      close: 'Stäng',
      startReferral: 'Gör egenremiss',
      next: 'Nästa',
      finishReferral: 'Skicka egenremiss',
      selftest: 'Gör självtest',
      menu: 'Meny',
      update: 'Uppdatera',
      help: {
        referral: 'Var är en egenremiss?',
      },
    },
    login: {
      header: 'Vuxenpsykiatrins öppenvård',
      bankid: 'Logga in med BankID',
      siths: 'Logga in med SITHS-kort',
      info: 'Logga in för att göra egenremiss och följa ditt ärende',
      sithsinfo: 'Logga in för att se och hantera svar',
      error: {
        deleted: 'Användarkontot är inte aktivt.',
        unauthorized:
          'Kunde inte logga in. Användare med detta HSA-ID finns inte i systemet.',
        general: 'Ett allmänt fel har inträffat. Var god försök igen.',
        siths:
          'Ett fel har inträffat vid SITHS-inloggning. Var god försök igen.',
      },
    },
    routes: {
      home: 'Min sida',
      questionnaire: 'Formulär',
      history: 'Min historik',
      emergency: 'Akut kontakt',
      selfcare: 'Egenvård',
      settings: 'Inställningar',
      faq: 'Frågor och svar',
      login: 'Logga in',
      startReferral: 'Gör Egenremiss',
      logout: 'Logga ut',
      referrals: 'Aktuella remisser',
      patients: 'Våra patienter',
      admin: {
        patients: 'Administrera patienter',
        users: 'Administrera användare',
        organisations: 'Administrera organisationer',
        usagestats: 'Statistik',
        packages: 'Administrera paket',
        forms: 'Administrera formulär',
        exportformscores: 'Datauttag',
      },
    },
    settings: {
      language: 'Språk',
      consent: 'Samtycke',
    },
    info: {
      logout: 'Loggar ut...',
      ownreferral_unanswered: 'Formuläret för livssituation är inte besvarat',
      undecisive_answer: 'Besvarat med värde',
      copyError:
        'Det gick inte att automatiskt kopiera journaltexten till din urklippshanterare. Du kan istället trycka Ctrl+C för att kopiera den markerade texten.',
    },
    stats: {
      weekly: {
        referrals: 'nya remisser',
        updates: 'kompletterade remisser',
      },
      overall: {
        monthly: 'Senaste 12 mån.',
        weekly: 'Denna vecka',
        results: 'Vårdresultat',
      },
      monthly: {
        days: 'Nya remisser',
        recurring: 'Återremitterade',
        volunteers: 'Frivillga remisser',
      },
    },
    header: {
      selfcare: `Medan du väntar på vård eller mellan besöken, finns det saker du kan göra själv för att må bättre. Att följa enkla dagliga rutiner är ett sätt.<br /><br />
            I nästa vy ser du 16 symboler som står för vanliga aktiviteter. De är värda olika poäng, beroende på ansträngning.<br /><br />
            Räkna vad du klarar av att göra under dagen och sätt upp mål för några dagar framåt.
            `,
      selftest:
        'Här kan du kan göra ett av våra självtest. De hjälper dig bedöma hur olika faktorer påverkar din hälsa.',
    },
    tableheaders: {
      patient: {
        patientId: '',
        patient: '',
        own_referral_date: 'Datum egenremiss',
        accepted: 'Antagen',
        priority: 'Prio',
        whodas36: 'WHODAS',
        madrs: 'MADRS',
        audit: 'AUDIT',
        dudit: 'DUDIT',
        dsm5: 'DSM-5',
        lpfsbf: 'LPFS',
        asrs: 'ASRS',
        pid5: 'PID-5',
        raads14: 'RAADS-14',
        raads80: 'RAADS-80',
        core10: 'CORE-10',
        coreom: 'CORE-OM',
      },
      referral: {
        patientId: '',
        patient: '',
        own_referral_date: 'Dagar sedan remiss',
        accepted: 'Antagen',
        priority: 'Prio',
        whodas36: 'WHODAS',
        madrs: 'MADRS',
        audit: 'AUDIT',
        dudit: 'DUDIT',
        dsm5: 'DSM-5',
        lpfsbf: 'LPFS',
        asrs: 'ASRS',
        pid5: 'PID-5',
        raads14: 'RAADS-14',
        raads80: 'RAADS-80',
        core10: 'CORE-10',
        coreom: 'CORE-OM',
      },
    },
    journaltext: {
      description: {
        madrs: `MADRS-S (självskattning av depression): %score%p, vilket indikerar %score_snippet%`,
        dudit: `DUDIT (screening av droganvändande): %score%p vilket indikerar %score_snippet%`,
        audit: `AUDIT (screening av alkoholvanor): %score%p vilket indikerar %score_snippet%`,
        whodas36: `WHODAS 36 (självskattning av funktionsnivå): T %score%, Livsdomäner med värden som är lika med eller över T 65 tyder på funktionsnedsättning. %score_snippet% %domain_snippet%`,
        dsm5: `DSM-5 (screening av olika psykiska symtom). Patienten anger större svårigheter än befolkningen i allmänhet när det gäller följande domäner:\r\n%score_snippet%`,
        asrs: `ASRS (screening för adhd). Frågor om barndomssymtom aktualiseras endast om vuxensymtomen är omfattande. Dessa redovisas dock inte genom poäng utan kan användas som intervjuunderlag. %result_snippet% Patienten skattar %inadvertency_score%/9 symtom av ouppmärksamhet respektive %hyperactivity_score%/9 hyperaktivitet/impulsivitet. Denna screening utgör inte i sig tillräckligt underlag för ADHD diagnos.`,
        lpfsbf: `LPFS-BF (en kort screening för att bedöma grad av personlighetsfunktion där 1,5 eller mer i åtminstone två områden kan indikera personlighetssyndrom). %score_snippet%`,
        pid5: {
          header: `PID-5 är ett självskattningsformulär för att bedöma personlighetsdysfunktion. De domäner eller egenskaper som bedöms vara kliniskt signifikanta (lika med eller över T=65; lika med eller under T=35) dokumenteras nedan:`,
          domains: `Domäner`,
          negativeaffect: `Negativ affekt T = %negative_affect% \r\nAtt man ofta och intensivt upplever höga nivåer av flera negativa känslor och agerar efter dessa`,
          detachment: `Avstängdhet/Distansering T = %detachment% \r\nMan undviker umgänge och relationer med andra. Man drar sig undan i mellanmänskliga relationer som vänskapsrelationer eller kärleksrelationer. Man har även en begränsad förmåga att uppleva känslor och att uttrycka känslor samt en begränsad förmåga att njuta.`,
          antagonism: `Antagonism T = %antagonism% \r\nBeteenden som gör att man kommer i konflikt med andra, vilket även kan innefatta att man anser sig vara en extra viktig person som förtjänar att behandlas extra väl. Man kan också ha ett känslokallt förhållningssätt gentemot andra människor. Man kan tycka att det är svårt förstå andras behov och känslor. Man kan ha en benägenhet att hävda sig på andras bekostnad.`,
          disinhibition: `Dysinhibering T = %disinhibition% \r\nMan söker omedelbar behovstillfredsställelse, vilket kan leda till impulsivt beteende utifrån tankar och känslor i stunden. Man kan handla impulsivt utifrån yttre stimuli, utan att ta hänsyn till tidigare erfarenheter eller att betänka konsekvenserna.`,
          psychoticism: `Psykotism T = %psychoticism% \r\nMan uppvisar en rad olika udda, excentriska eller ovanligt förekommande beteenden och föreställningar som är avvikande från hur andra uppfattar det. Detta innefattar både hur man varseblir saker och ting och hur man tolkare det.`,
          properties: `Egenskaper`,
          submissiveness: `Undergivenhet: T = %submissiveness% \r\nMan anpassar sig och sitt beteende till hur man tror att andra vill att man ska göra eller tycka`,
          depressivity: `Depressivitet: T = %depressivity% \r\nÅterkommande känslor av nedstämdhet, plågsamhet och/eller hopplöshet`,
          separationinsecurity: `Separationsångest: T = %separationinsecurity%\r\nRädsla för avvisande av eller bli skild ifrån viktiga relationer`,
          anxiousness: `Ängslighet: T = %anxiousness% \r\nIntensiva oroskänslor eller panik`,
          emotionallability: `Känslomässig instabilitet: T = %emotionallability% \r\nKänslor är lättväckta, intensiva och/eller oproportionerliga`,
          suspiciousness: `Misstänksamhet: T = %suspiciousness% \r\nTvivlar på andras lojalitet och trofasthet`,
          restrictedaffectivity: `Begränsade känslouttryck: T = %restrictedaffectivity% \r\nOproportionerligt små reaktioner inför känsloväckande situationer`,
          withdrawal: `Tillbakadragenhet: T = %withdrawal% \r\nUndvikande av sociala kontakter och aktiviteter`,
          intimacyavoidance: `Undvikande av närhet: T = %intimacyavoidance% \r\nUndvikande av nära och romantiska förhållanden`,
          anhedonia: `Anhedoni, likgiltighet: T = %anhedonia% \r\nBrist på lust för saker som tidigare var lustfyllda`,
          manipulativeness: `Manipulativ: T = %manipulativeness% \r\nAnvänder sig exempelvis av förförelse, falskt tillmötesgående eller charm för att uppnå egna mål`,
          deceitfulness: `Bedräglighet: T = %deceitfulness% \r\nHar tendenser till att vara oärlig och svika andra`,
          hostility: `Fientlighet: T = %hostility% \r\nReagerar med irritation eller ilska på kritik eller mindre allvarliga förolämpningar`,
          callousness: `Känslolöshet: T = %callousness% \r\nBrist på hänsyn för andras känslor eller svårigheter`,
          attentionseeking: `Uppmärksamhetssökande: T = %attentionseeking% \r\nÖverdrivna försök att dra till sig och vara i fokus för andras uppmärksamhet`,
          grandiosity: `Grandiositet: T = %grandiosity% \r\nUpplevelser av berättigande; självcentrering; föreställning att man är bättre än andra`,
          irresponsibility: `Ansvarslöshet: T = %irresponsibility% \r\nBrist på respekt för överenskommelser och löften`,
          implusivity: `Impulsivitet: T = %implusivity% \r\nOmedelbart handlande utan planering eller tankar på konsekvenser`,
          risktaking: `Risktagande: T = %risktaking% \r\nGe sig in i farliga, riskfyllda och potentiellt självskadande situationer`,
          distractability: `Distraherbarhet: T = %distractability% \r\nMan har koncentrationssvårigheter och svårt att fokusera på uppgifter`,
          perseveration: `Perseveration: T = %perseveration% \r\nFastna i uppgifter långt efter beteendet upphört att vara effektivt`,
          rigidperfectionism: `Rigid perfektionism: T = %rigidperfectionism% \r\nOresonligt insisterande på att det skall vara felfritt, perfekt, utan misstag eller fel`,
          eccentricity: `Excentricitet: T = %eccentricity% \r\nUdda, ovanligt eller bisarrt beteende eller utseende`,
          perceptualdysregulation: `Kognitiv/perceptuell dysreglering: T = %perceptualdysregulation% \r\nUdda, omständligt tal; ovanliga tankeprocesser, derealisation eller depersonalisering`,
          usualbeliefsandexperiences: `Ovanliga föreställningar: T = %usualbeliefsandexperiences% \r\nFöreställningar om särskilda förmågor som att läsa tankar eller telekinesi`,
          validityscales: 'Validitetsskalor:',
          ors: 'Overreporting Scale %ors% – gränsvärde = 3',
          ris: 'Report Inconsistency Scale %ris% – gränsvärde = 18',
          valid: {
            0: 'Det kan vara en risk att patientens svar kan ha bristande tillförlighet. Tolka svaren med försiktighet.',
            1: 'Patientens svar bedöms som tillförlitliga.',
          },
          screening: 'Screening:',
          borderline:
            'Borderline personlighetssyndrom %borderline% – gränsvärde = 11',
          validScreening: {
            0: 'Fördjupad bedömning av borderline personlighetssyndrom bör genomföras.',
            1: 'Patienten uppfyller sannolikt inte kriterier för borderline personlighetssyndrom.',
          },
          nothingabnormalprop:
            'Det finns inga egenskaper som indikerar klinisk signifikans.',
          nothingabnormaldom:
            'Det finns inga domäner som indikerar klinisk signifikans.',
        },
        whodas12:
          'WHODAS 12: T %score%, Det betyder att %score_snippet%\r\n',
        raads14: 'RAADS-14 (screening för autism): %score%p, vilket ',
        raads80:
          'RAADS-80 (självskattning för autism, cut-off är 98 och högre): %score%p/240p. ',
        coreom:
          'CORE-OM (mäter psykisk hälsa) Totalvärdet indikerar övergripande problemnivå. Riskvärdet bedöms som högt om det är över 3. Ett högt riskvärde indikerar att det kan finnas risker för patientens hälsa eller för personer i patientens närhet. En skillnad på 5 poäng eller mer mellan två skattningar indikerar en tillförlitlig förändring.\r\n\r\n',
        core10:
          'CORE-10 (uppföljningsmätning psykiskt mående) En skillnad på 5 poäng eller mer mellan två skattningar indikerar en tillförlitlig förändring.\r\n\r\n',
        diva5:
          'Diagnostisk intervju för ADHD hos vuxna, DIVA-5. %interview_type%. DIVA-5 består av 3 delar som var och en täcker både barndom och vuxen ålder: kriterier för ouppmärksamhet; kriterier för hyperaktivitet-impulsivitet; debutålder och funktionsnedsättning orsakad av eventuella ADHD symtom. För ouppmärksamhet i vuxen ålder bedöms %oupp_vuxen%/9 symtomkriterier uppfyllda. I barndomen bedöms %oupp_barn%/9 ouppmärksamhetskriterier uppfyllda. För hyperaktivitet-impulsivitet i vuxen ålder bedöms %hyper_vuxen%/9 kriterier uppfyllda. I barndomen bedöms %hyper_barn%/9 symtomkriterier för hyperaktivitet-impulsivitet uppfyllda. Kriterier för gränsvärde av 5/9 symtomkriterier för vuxen-ADHD bedöms %yes_no%uppfyllda.',
          pcl5: 'PCL-5 (självskattningsformulär för PTSD). %score_text%'
      },
      scores: {
        madrs: {
          1: 'ingen eller mycket lätt depression',
          2: 'lätt depression',
          3: 'måttlig depression',
          4: 'svår depression',
          suicidalRisk:
            'Patienten indikerar högsta värdet på livslustfråga, vilket är ett observandum för hög suicidrisk. En fördjupad bedömning beskrivs under sökord suicidrisk.',
        },
        dudit: {
          some: 'viss droganvändning. Bör utredas vidare',
          none: 'ingen droganvändning.',
          1: 'viss droganvändning.',
          2: 'drogrelaterade problem.',
          3: 'att beroende föreligger.'
        },
        audit: {
          1: 'sannolikt inget skadligt bruk',
          2: 'riskbruk av alkohol',
          3: 'skadligt bruk av alkohol',
          4: 'alkoholberoende',
        },
        whodas36: {
          1: 'behandling på lägre nivå än specialistpsykiatri kan vara aktuellt om det inte finns några andra skäl till specialiserad vård.',
          2: 'Patienten upplever sin funktion vara mer nedsatt än normalpopulationen.',
          // 3: 'patienten upplever sin funktion vara mer nedsatt än 90% av befolkningen.',
          domain: {
            ingress: `Följande funktionsområden kan vara särskilt nedsatta:\r\n`,
            1: `Förstå och kommunicera – T %domain_score%`,
            2: `Förflyttning - T %domain_score%`,
            3: `Personlig vård - T %domain_score%`,
            4: `Relationer - T %domain_score%`,
            5: `Dagliga aktiviteter – totalt - T %domain_score%`,
            '5_household': `Dagliga aktiviter – hushåll - T %domain_score%`,
            '5_work': `Dagliga aktiviteter – arbete/studier - T %domain_score%`,
            6: `Delaktighet i samhället - T %domain_score%`,
            none: `Inget funktionsområde upplevs som nedsatt.`,
          },
          irt: {
            1: 'stor aktivitetsbegränsning.',
            2: 'mycket stor/total aktivitetsbegränsning.',
          },
        },
        dsm5: {
          none: 'Inga domäner bedöms vara kliniskt signifikanta',
          suicidalRisk:
            'Patienten indikerar högsta värdet på självskadefråga, vilket är ett observandum för hög suicidrisk. En fördjupad bedömning beskrivs under sökord suicidrisk.',
        },
        asrs: {
          criteriaFulfilled: 'Patientens skattning tyder på ADHD.',
          criteriaNotFulfilled: 'Patientens skattning tyder inte på ADHD.',
        },
        lpfsbf: {
          subScoreAboveCutoff:
            'Skattningen indikerar att signifikant nedsättning av personlighetsfunktion föreligger.',
          subScoreBelowCutoff:
            'Skattningen indikerar att ingen signifikant nedsättning av personlighetsfunktion föreligger.',
          subElementText:
            'Underordnade elements medelvärden: Identitet %identity_score%; Självstyrning %selfdirection_score%; Empati %empathy_score%; Närhet %intimacy_score%',
          internal: {
            aboveCutoff: 'Självfunktion: medelvärde %internal_score%.',
            belowCutoff: 'Självfunktion: medelvärde %internal_score%.',
            subElementText:
              'Underordnade elements medelvärden: Identitet %identity_score%; Självstyrning %selfdirection_score%',
          },
          interpersonal: {
            aboveCutoff:
              'Mellanmänsklig funktion: medelvärde %interpersonal_score%.',
            belowCutoff:
              'Mellanmänsklig funktion: medelvärde %interpersonal_score%.',
            subElementText:
              'Underordnade elements medelvärden: Empati %empathy_score%; Närhet %intimacy_score%',
          },
        },
        whodas12: {
          1: 'patienten upplever sig fungera som befolkningen i allmänhet.',
          2: 'patienten upplever sig vara mer nedsatt i sin funktion än befolkningen i allmänhet'
        },
        raads14: {
          above:
            'indikerar att autismspektrumtillstånd kan föreligga och kan behöva undersökas närmare.',
          below: 'indikerar att autismspektrumtillstånd inte föreligger.',
        },
        raads80: {
          above:
            'Resultatet indikerar att autismspektrumtillstånd kan föreligga och ger indikationer på att mentaliseringssvårigheter, social ångest och sensorisk känslighet föreligger.',
          below:
            'Resultatet indikerar att autismspektrumtillstånd inte föreligger.',
          namedScores: 'Resultat på respektive delskala:',
          socialRelatedness: 'Social förmåga: %social_score%p/117p',
          circumscribedInterests:
            'Begränsade intressen: %circumscribed_score%p/42p',
          language: 'Kommunikation: %language_score%p/21p',
          sensoryMotor: 'Känslighet för sinnesintryck: %sensory_score%p/60p',
        },
        coreom: {
          total:
            'Totalvärde: %total_score%p, vilket motsvarar %total_text%.\r\n',
          risk:
            'Riskvärde: %risk_score%p, vilket indikerar en %risk_status% risk för patientens hälsa eller för personer i patientens närhet.\r\n',
          healthy: 'frisk',
          low: 'en låg problemnivå',
          mild: 'en mild problemnivå',
          moderate: 'en måttlig problemnivå',
          moderateSerious: 'en måttlig till allvarlig problemnivå',
          serious: 'en allvarlig problemnivå',
        },
        core10: {
          total:
            'Totalvärde: %total_score%p, vilket motsvarar %total_text%.\r\n',
          healthy: 'frisk',
          low: 'en låg problemnivå',
          mild: 'en mild problemnivå',
          moderate: 'en måttlig problemnivå',
          moderateSerious: 'en måttlig till allvarlig problemnivå',
          serious: 'en allvarlig problemnivå',
        },
        diva5: {
          adult:
            'DIVA-5 indikerar att vuxen-ADHD inte föreligger eftersom det inte finns tillräckliga symptom.',
          lifelong:
            'Det finns %yes_no%ett livslångt mönster av symtom och begränsningar innan 12 års ålder.',
          development:
            'DIVA-5 indikerar att vuxen-ADHD inte föreligger eftersom det inte finns tillräckliga utvecklingsrelaterade symtom.',
          func:
            'Det föreligger %yes_no%funktionsnedsättning inom minst två livsområden, %functions%.',
          funcNo:
            'DIVA-5 indikerar att vuxen-ADHD inte föreligger eftersom det inte finns tillräckliga funktionsnedsättningar.',
          symp:
            'Symtomen förklaras %yes_no%bättre med någon annan psykiatrisk diagnos.',
          sympBetter:
            'DIVA-5 indikerar att vuxen-ADHD inte föreligger eftersom svårigheterna förklaras bättre av den psykiatriska diagnosen %symp_better%.',
          functions: {
            Familj: 'Relationer och / eller familj',
            Fritid: 'Fritid / fritidsintressen',
            Social: 'Sociala kontakter',
            Utbildning: 'Arbete / utbildning',
            Sjalvfortroende: 'Självförtroende / självbild',
          },
          type: {
            1: 'Patientintervju',
            2: 'Anhörigintervju',
            3: 'Patient- och anhörigintervju',
          },
        },
        pcl5: {
          provisional_yes: 'Resultaten indikerar att PTSD kan föreligga',
          provisional_no: 'Resultaten indikerar att PTSD inte föreligger',
          total: 'Totalpoäng: %total_score%p',
          cluster_b: 'B-kluster (påträngande symtom): %score%p/20p (%answered%)',
          cluster_c: 'C-kluster (undvikanden): %score%p/8p (%answered%)',
          cluster_d: 'D-kluster (negativa förändringar i emotioner och sinnesstämningar): %score%p/28p (%answered%)',
          cluster_e: 'E-kluster (symtom på förhöjd fysiologisk aktivering): %score%p/24p (%answered%)'
        }
      },
    },
    form: {
      namedscores: {
        asrs: {
          inadvertency: 'Ouppmärksamhet',
          hyperactivity: 'Hyperaktivitet',
        },
        lpfsbf: {
          internal: '<b>Självfunktion</b>',
          identity: 'Identitet (medelvärde)',
          selfdirection: 'Självstyrning (medelvärde)',
          interpersonal: '<b>Mellanmänsklig funktion</b>',
          empathy: 'Empati (medelvärde)',
          intimacy: 'Närhet (medelvärde)',
          totalscore: '<b>Råpoäng</b>'
        },
        whodas12: {
          movement: 'Förflyttning (medelvärde)',
          dailyhousehold: 'Dagliga aktiviteter- hushåll',
          communication: 'Förstå och kommunicera (medelvärde)',
          societal: 'Delaktighet i samhället (medelvärde)',
          personalcare: 'Personlig vård (medelvärde)',
          relations: 'Relationer (medelvärde)',
          dailyworkeducation: 'Dagliga aktiviteter – arbete/utbildning',
        },
        pid5: {
          properties: 'Egenskaper',
          domains_icd11: 'Domäner',
          t_score_norm_bach: 'T-Värden',
          borderline_algorithm: 'Borderline Algoritm',
          over_reporting_scale: 'ORS',
          response_inconsistency_scale: 'Response Inconsistency Scale',
          submissiveness: 'Undergivenhet',
          depressivity: 'Depressivitet',
          separationinsecurity: 'Separationsångest',
          anxiousness: 'Ängslighet',
          emotionallability: 'Känslomässig instabilitet',
          suspiciousness: 'Misstänksamhet',
          restrictedaffectivity: 'Begränsade känslouttryck',
          withdrawal: 'Tillbakadragenhet',
          intimacyavoidance: 'Undvikande av närhet',
          anhedonia: 'Anhedoni',
          manipulativeness: 'Manipulativt beteende',
          deceitfulness: 'Bedräglighet',
          hostility: 'Fientlighet',
          callousness: 'Känslolöshet',
          attentionseeking: 'Uppmärksamhetssökande',
          grandiosity: 'Grandiositet',
          irresponsibility: 'Ansvarslöshet',
          implusivity: 'Impulsivitet',
          risktaking: 'Risktagande',
          distractability: 'Distraherbarhet',
          perseveration: 'Perseveration',
          rigidperfectionism: 'Rigid perfektionism',
          eccentricity: 'Excentricitet',
          perceptualdysregulation: 'Perceptuell dysreglering',
          usualbeliefsandexperiences: 'Ovanliga föreställningar & beteenden',
          negativeaffect: 'Negativ affekt',
          detachment: 'Distansering',
          dissociality: 'Dyssocialitet',
          disinhibition: 'Dysinhibering',
          compulsiveness: 'Anankastisk/tvångsmässig',
          antagonism: 'Antagonism',
          psychoticism: 'Psykotism',
          invalidScale:
            'Det kan vara en risk att patientens svar kan ha bristande tillförlighet. Tolka svaren med försiktighet.',
          validScale: 'Patientens svar bedöms som tillförlitliga.',
          limit: 'gränsvärde',
          validityscales: 'Validitetsskalor',
          screening: 'Screening',
          validScreening:
            'Patienten uppfyller sannolikt inte kriterier för borderline personlighetssyndrom',
          invalidScreening:
            'Fördjupad bedömning av borderline personlighetssyndrom bör genomföras',
        },
        raads14: {
          mentalizing_difficulties: 'Mentaliseringssvårigheter',
          social_anxiety: 'Social ångest',
          sensory_reactivity: 'Sensorisk reaktivitet',
        },
        raads80: {
          social_relatedness: 'Social förmåga',
          circumscribed_interests: 'Begränsade intressen',
          language: 'Kommunikation',
          sensory_motor: 'Känslighet för sinnesintryck',
        },
        coreom: {
          risk: 'Risk',
          problem: 'Problem',
          functioning: 'Fungerande',
          well_being: 'Välbefinnande',
          total_no_risk: 'Alla delskalor utom risk',
        },
        pcl5: {
          cluster_b: 'Kluster B',
          cluster_c: 'Kluster C',
          cluster_d: 'Kluster D',
          cluster_e: 'Kluster E',
          score_b: 'Provisional B',
          score_c: 'Provisional C',
          score_d: 'Provisional D',
          score_e: 'Provisional E',
          provisional_ptsd: 'Provisional PTSD'
        }
      },
    },
    user: {
      DIVA5: 'DIVA-5',
      forms: {
        Betyg: 'Betyg/omdömen/skolrapporter',
        BarnSy:
          'Föreligger ett flertal (3 eller fler) för A och / eller HI? (Barn)',
        Diagnos: 'Diagnos',
        PsykStoB:
          'Symptom och funktionsnedsättning föreligger inom minst två funktionsdomäner (Barn)',
        PsykStoV:
          'Symptom och funktionsnedsättning föreligger inom minst två funktionsdomäner (Vuxen)',
        PsykAnnat:
          'Symptomen kan inte förklaras (bättre) med någon annan föreliggande psykiatrisk störning',
        VuxenOupp: 'Är antalet kritierier på ouppmärksamhet (A) > 5? (Vuxen)',
        Stodfamilj: 'Förälder(föräldrar)/bror/syster/övrig',
        VuxenHyper:
          'Är antalet kriterier på hyperaktivitet/impulsivitet (H/I) > 5? (Vuxen)',
        LivsLangtSymp:
          'Finns det tecken på ett livslångt mönster av symptom med debut före 12 års ålder?',
        Stodpartner: 'Partner/god vän(inna)/övrig',
        diagnos: {
          false: 'Nej',
          1: '314.01 - F900B ADHD med såväl ouppmärksamhet och hyperaktivitet/impulsivitet',
          2: '314.00 - F900C ADD huvudsakligen med ouppmärksamhet',
          3: '314.01 - F900B ADHD huvudsakligen med hyperaktivitet/impulsivitet',
          4: '314.01 - F908 Annan specificerad ADHD',
          5: '314.01 - F900X Annan ospecificerad ADHD',
          6: 'Partiell remission',
        },
      },
    },
    research: {
      info: 'Samtycker till att delta i studien',
      blood:
        'Samtycker till att lämna blodprov för analys av läkemedelskoncentration',
      biobank: 'Samtycker till att prover sparas i en biobank',
      consent:
        'Samtycker till att uppgifter behandlas på det sätt som beskrivs i forskningspersonsinformationen',
      hypermobility: 'Samtycker till att även delta i studie om överrörlighet',
      signedWithBankId: 'Samtycke har signerats med BankID',
    },
  },
  en: {
    placeholder: {
      input: {
        ssn: 'Enter SSN',
      },
    },
    button: {
      start: 'Start',
      close: 'Close',
      startReferral: 'Start self referral',
      next: 'Next',
      finishReferral: 'Send self referral',
      selftest: 'begin self test',
      menu: 'Menu',
      update: 'Update',
      help: {
        referral: 'What is a self referral?',
      },
    },
    login: {
      header: 'Psychiatric care',
      bankid: 'Log in with BankID',
      siths: 'Log in with SITHS-card',
      info:
        'Log in to fill out self referral and follow up on your submissions',
      sithsinfo: 'Log in to view and handle answers',
    },
    routes: {
      home: 'My home',
      questionnaire: 'Form',
      history: 'My history',
      emergency: 'Emergency contact',
      selfcare: 'Self care',
      settings: 'Settings',
      faq: 'FAQ',
      login: 'Log in',
      startReferral: 'Start self referral',
      logout: 'Log out',
      referrals: 'Current referrals',
      patients: 'Our patients',
      admin: {
        users: 'Administer users',
        patients: 'Administer patients',
        organisations: 'Administer organisations',
        usagestats: 'Statistics',
        forms: 'Administer forms',
      },
    },
    settings: {
      language: 'Language',
      consent: 'Consent',
    },
    info: {
      logout: 'Logging out...',
      ownreferral_unanswered: 'Self referral form not answered',
      undecisive_answer: 'Answered with value',
    },
    stats: {
      weekly: {
        referrals: 'new referrals',
        updates: 'updated referrals',
      },
      overall: {
        monthly: 'Last 12 months',
        weekly: 'This week',
        results: 'Care results',
      },
    },
  },
};
