<template>
  <div class="container mx-auto bg-white">
    <Header title="Administrera patienter" />

    <div v-if="!loaded" class="w-full h-64 flex content-center justify-center">
      <div>
        <font-awesome-icon icon="spinner" spin></font-awesome-icon>
        <span class="pl-2">Laddar... </span>
      </div>
    </div>

    <div class="px-4 py-4" v-else>
      <div class="flex p-2 justify-between">
        <div>
          <div class="flex" v-if="!revealList">
            <BaseInputSsn
              v-model="ssn"
              :validSsn.sync="validSsn"
              @enter="searchBySsn"
            />
            <button
              @click="searchBySsn"
              :disabled="!validSsn"
              type="button"
              class="ml-1 px-2 py-1 text-sm rounded"
              :class="{
                'cursor-not-allowed bg-grey text-grey-darkest border-grey-darker': !validSsn,
                'text-blue border border-blue hover:bg-blue hover:text-white': validSsn,
              }"
            >
              Sök
            </button>
          </div>
        </div>
        <div>
          <button
            @click="reveal()"
            v-if="!revealList"
            type="button"
            class="text-red border border-red hover:bg-red hover:text-white rounded text-sm px-4 py-1"
          >
            Visa patientlista
          </button>
          <button
            @click="reveal()"
            v-if="revealList"
            type="button"
            class="text-blue border border-blue hover:bg-blue hover:text-white rounded text-sm px-4 py-1"
          >
            Göm patientlista
          </button>
        </div>
      </div>

      <div v-if="revealList">
        <div class="w-full bg-white px-2 py-2">
          <div class="flex justify-between mb-2">
            <div>Visar {{ listCount }} av {{ listTotal }} patienter.</div>
          </div>

          <input
            placeholder="Ange namn eller personnummer för att söka i listan"
            type="text"
            name="searchTerms"
            v-model="searchTerms"
            class="border border-grey rounded-lg px-2 py-2 w-full"
          />
        </div>

        <table class="w-full">
          <thead>
            <tr @click="sortPatients">
              <th class="w-8"></th>
              <th data-sort="id" class="text-left">ID</th>
              <th data-sort="firstName" class="text-left">Förnamn</th>
              <th data-sort="lastName" class="text-left">Efternamn</th>
              <th data-sort="nationalId" class="text-left">Personnummer</th>
              <th data-sort="mobile" class="text-left">Telefon</th>
            </tr>
          </thead>
          <tbody v-if="patients">
            <tr v-for="item in sortedPatients" :key="item.id">
              <th class="pb-1" title="Redigera">
                <font-awesome-icon
                  @click="editPatient(item)"
                  icon="pen"
                  class="mx-2 text-blue align-middle cursor-pointer hover:text-blue-darker"
                ></font-awesome-icon>
              </th>
              <td class="text-left">{{ item.id }}</td>
              <td class="text-left">{{ item.first_name }}</td>
              <td class="text-left">{{ item.last_name }}</td>
              <td class="text-left">{{ item.national_id }}</td>
              <td class="text-left">{{ item.mobile }}</td>
            </tr>
          </tbody>
        </table>

        <div v-if="!patients" class="text-center mt-16 w-full">
          <font-awesome-icon
            icon="glasses"
            class="text-8xl mx-2 text-grey-dark align-middle"
          ></font-awesome-icon>
          <div class="text-grey-dark">Inga patienter matchade sökningen</div>
        </div>

        <div class="pagination-div" v-if="patients">
          <paginate
            :page-count="pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="changePage"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :prev-class="'prev-page'"
            :next-class="'next-page'"
          ></paginate>
        </div>
      </div>
      <table class="w-full" v-else-if="foundPatient">
        <thead>
          <tr @click="sort">
            <th class="w-8"></th>
            <th data-sort="first_name" class="text-left">Förnamn</th>
            <th data-sort="last_name" class="text-left">Efternamn</th>
            <th data-sort="national_id" class="text-left">Personnummer</th>
            <th data-sort="mobile" class="text-left">Telefon</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="pb-1" title="Redigera">
              <font-awesome-icon
                @click="editPatient(foundPatient)"
                icon="pen"
                class="mx-2 text-blue align-middle cursor-pointer hover:text-blue-darker"
              ></font-awesome-icon>
            </th>
            <td class="text-left">{{ foundPatient.first_name }}</td>
            <td class="text-left">{{ foundPatient.last_name }}</td>
            <td class="text-left">{{ foundPatient.national_id }}</td>
            <td class="text-left">{{ foundPatient.mobile }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-6">
        <button
          @click="createNewPatient()"
          type="button"
          class="block px-2 py-2 rounded-lg text-lg bg-green border border-white text-white m-2"
        >
          Lägg till
        </button>
      </div>
    </div>

    <portal to="modals">
      <modal
        name="editPatientModal"
        height="auto"
        width="800px"
        :scrollable="true"
      >
        <div class="relative p-4 bg-white w-full rounded-lg shadow">
          <form>
            <div class="mb-2">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="first_name"
              >
                Förnamn
              </label>
              <input
                v-model="patient.first_name"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="first_name"
                type="text"
                placeholder=""
              />
            </div>

            <div class="mb-2">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="last_name"
              >
                Efternamn
              </label>
              <input
                v-model="patient.last_name"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="last_name"
                type="text"
                placeholder=""
              />
            </div>

            <div class="mb-2">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="national_id"
              >
                Personnummer
              </label>
              <input
                v-model="patient.national_id"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="national_id"
                type="text"
                placeholder=""
              />
            </div>

            <div class="mb-2">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="mobile"
              >
                Mobilnummer
              </label>
              <input
                v-model="patient.mobile"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                id="mobile"
                type="text"
                placeholder=""
              />
            </div>

            <div v-if="isSuperAdmin">
              <div class="mb-2">
                <label
                  class="block text-grey-darker text-sm font-bold mb-2"
                  for="organisation"
                >
                  Organisation
                </label>
                <select
                  v-model="patient.organisation_id"
                  name="organisation"
                  id="organisation"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                >
                  <option
                    v-for="(org, index) in organisations"
                    :key="index"
                    :value="org.id"
                  >
                    {{ org.name }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <div class="mb-2">
                <label
                  for=""
                  class="block text-grey-darker text-sm font-bold mb2"
                  >SMS-påminnelser</label
                >
                <div class="flex items-center">
                  <input
                    class="m-2"
                    type="checkbox"
                    id="patient_reminder_consent"
                    v-model="patient.reminder_consent"
                  />
                  <label class="m-2" for="patient_reminder_consent"
                    >Patienten samtycker till sms-påminnelser</label
                  >
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="mb-2 mr-2">
                <label
                  class="block text-grey-darker text-sm font-bold mb-2"
                  for="packages"
                >
                  Paket
                </label>
                <div
                  class="flex p-1"
                  v-for="(pack, index) in patientpackages"
                  :key="index"
                >
                  <div>
                    <div>
                      <input
                        v-model="pack.checked"
                        type="checkbox"
                        :name="pack.title"
                        :id="pack.title"
                      />
                      <label :for="pack.title" :title="packTitle(pack.forms)">{{
                        pack.title
                      }}</label>
                    </div>
                    <div
                      v-if="
                        pack.checked &&
                          patient.reminder_consent &&
                          !['Nybesök'].includes(pack.title)
                      "
                    >
                      <input
                        v-model="pack.reminder"
                        type="checkbox"
                        :name="'pack_reminder_' + index"
                        :id="'pack_reminder_' + index"
                      />
                      <label :for="'pack_reminder_' + index">Påminnelse</label>
                      <br />
                      <select
                        v-if="pack.reminder"
                        :name="'pack_reminder_period_' + index"
                        :id="'pack_reminder_period_' + index"
                        v-model="pack.reminder_period"
                        class="shadow appearance-none border rounded py-1 px-1 text-grey-darker leading-tight"
                        title="Period"
                        @change="changedPeriod(index)"
                      >
                        <option :value="null"></option>
                        <option :value="1">Engångs</option>
                        <option :value="2">Dagligen</option>
                        <option :value="3">Veckovis</option>
                        <option :value="4">Månadsvis</option>
                      </select>
                      <select
                        v-if="
                          pack.reminder &&
                            pack.reminder_period != 2 &&
                            pack.reminder_period != 1
                        "
                        name="pack_reminder_day"
                        id="pack_reminder_day"
                        v-model="pack.reminder_day"
                        class="shadow appearance-none border rounded py-1 px-1 text-grey-darker leading-tight"
                        title="Dag"
                      >
                        <option :value="null"></option>
                        <option :value="1">Måndag</option>
                        <option :value="2">Tisdag</option>
                        <option :value="3">Onsdag</option>
                        <option :value="4">Torsdag</option>
                        <option :value="5">Fredag</option>
                        <option :value="6">Lördag</option>
                        <option :value="7">Söndag</option>
                      </select>
                      <input
                        v-if="pack.reminder && pack.reminder_period == 1"
                        v-model="pack.reminder_date"
                        type="date"
                        :name="'pack_reminder_date_' + index"
                        :id="'pack_reminder_date_' + index"
                        title="Datum"
                        class="shadow appearance-none border rounded py-1 px-1 text-grey-darker leading-tight"
                      />
                      <input
                        v-if="pack.reminder"
                        v-model="pack.reminder_time"
                        type="time"
                        :name="'pack_reminder_time_' + index"
                        :id="'pack_reminder_time_' + index"
                        title="Tid"
                        class="shadow appearance-none border rounded py-1 px-1 text-grey-darker leading-tight"
                      />
                      <input
                        v-if="pack.reminder && pack.reminder_period != 1"
                        v-model="pack.reminder_end_date"
                        type="date"
                        :name="'pack_reminder_end_date_' + index"
                        :id="'pack_reminder_end_date_' + index"
                        title="Slutdatum"
                        class="shadow appearance-none border rounded py-1 px-1 text-grey-darker leading-tight"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <label
                  class="block text-grey-darker text-sm font-bold mb-2"
                  for="packages"
                >
                  Formulär i "Självtest inför besök"
                </label>
                <div
                  class="p-1"
                  v-for="(form, index) in patientforms"
                  :key="index"
                >
                  <div>
                    <input
                      v-model="form.checked"
                      type="checkbox"
                      :name="form.code"
                      :id="form.code"
                      @change="handleSelectForm"
                    />
                    <label :for="form.code"
                      >{{ form.title }} - <b>{{ form.code }}</b></label
                    >
                  </div>
                </div>
                <div class="p-1">
                  <input
                    type="checkbox"
                    id="allForms"
                    @click="chooseAllForms"
                  />
                  <label for="allForms">Välj alla</label>
                </div>
              </div>
            </div>

            <div class="flex justify-end">
              <button
                class="bg-grey text-grey-darkest hover:bg-grey-dark font-bold py-2 px-4 rounded mr-6"
                type="button"
                @click="$modal.hide('editPatientModal')"
              >
                Avbryt
              </button>
              <button
                class="bg-blue hover:bg-blue-darker text-white font-bold py-2 px-4 rounded"
                type="button"
                @click="savePatient"
              >
                Spara
              </button>
            </div>

            <div v-if="errors.length > 0" class="mt-2 border-t-2 border-red">
              <div v-for="(error, key) in errors" :key="key" class="mt-1">
                {{ error.label }}
              </div>
            </div>
          </form>
        </div>
      </modal>
    </portal>
  </div>
</template>

<script>
import Header from "../Header.vue";
import debounce from "lodash/debounce";

export default {
  name: "AdminPatients",
  props: [],
  components: {
    Header,
  },
  data() {
    return {
      loaded: false,
      sortColumn: null,
      sortAsc: true,
      forms: null,
      packages: null,
      organisations: null,
      patient: {
        id: null,
        first_name: null,
        last_name: null,
        national_id: null,
        mobile: null,
        patienttype: null,
        organisation_id: null,
        reminder: null,
        reminder_period: null,
        reminder_day: null,
        reminder_time: null,
        reminder_date: null,
        reminder_message: null,
        reminder_consent: null,
      },
      errors: [],
      patientforms: [],
      patientpackages: [],
      ssn: null,
      validSsn: false,
      foundPatient: null,
      ssnSearchedFinished: false,
      revealList: false,
      searchTerms: ""
    };
  },
  watch: {
    searchTerms: {
      immediate: false,
      deep: true,
      handler: function() {
        this.search();
      }
    }
  },
  mounted() {
    // this.loadPatients();
    this.loadForms();
    this.loadPackages();
    if (this.isSuperAdmin) {
      this.loadOrganisations();
    }
    this.loaded = true;
  },
  computed: {
    sortedPatients() {
      if (this.sortColumn != null) {
        const x = this.patients;
        return x.sort((a, b) => {
          if (this.sortAsc) {
            return ("" + a[this.sortColumn]).localeCompare(b[this.sortColumn]);
          } else {
            return ("" + b[this.sortColumn]).localeCompare(a[this.sortColumn]);
          }
        });
      }

      return this.patients;
    },
    patients() {
      return this.$store.getters.adminPatientList;
    },
    listCount() {
      return this.$store.getters.adminPatientListCount;
    },
    listTotal() {
      return this.$store.getters.adminPatientListTotal;
    },
    total() {
      return this.$store.getters.adminTotal;
    },
    pages() {
      return this.$store.getters.adminPatientPages;
    },
    page() {
      return this.$store.getters.adminPage;
    },
    isSuperAdmin() {
      return this.$store.getters.isDev;
    },
  },
  methods: {
    sort(evt) {
      let col = evt.target.dataset.sort;
      this.sortAsc = this.sortColumn === col ? !this.sortAsc : true;
      this.sortColumn = col;
    },
    loadPatients() {
      this.$store.dispatch("loadPatients").then(() => {
        this.loaded = true;
      });
    },
    loadForms() {
      this.$store.dispatch("loadAllForms").then((response) => {
        this.patientforms = response.filter((a) => a.code !== 'REKO').map((d) => {
          return {
            ...d,
            checked: false
          };
        });
      });
    },
    loadPackages() {
      this.$store.dispatch("loadPackages").then((response) => {
        this.patientpackages = response.map((d) => {
          return {
            ...d,
            checked: false,
            reminder: false,
            reminder_date: null,
            reminder_time: null,
            reminder_day: null,
            reminder_period: null,
            reminder_end_date: null,
          };
        });
      });
    },
    loadOrganisations() {
      this.$store.dispatch("loadOrganisations").then(() => {
        this.organisations = this.$store.getters.availableOrganisations;
      });
    },
    editPatient(item) {
      this.errors = [];
      let {
        id,
        first_name,
        last_name,
        national_id,
        mobile,
        forms,
        packages,
        organisation_id,
        reminder,
        reminder_period,
        reminder_day,
        reminder_time,
        reminder_date,
        reminder_message,
        reminder_consent,
      } = item;
      this.patient = {
        id,
        first_name,
        last_name,
        national_id,
        mobile,
        organisation_id,
        reminder,
        reminder_period,
        reminder_day,
        reminder_time,
        reminder_date,
        reminder_message,
        reminder_consent,
      };
      this.patientforms = forms.filter((a) => a.code !== 'REKO');
      this.patientpackages = packages;
      this.$modal.show("editPatientModal");
    },
    getEmptyPatient() {
      return {
        id: null,
        first_name: null,
        last_name: null,
        national_id: null,
        mobile: null,
        patienttype: null,
        organisation_id: null,
        packages: this.patientpackages,
        forms: this.patientforms,
        reminder: null,
        reminder_period: null,
        reminder_day: null,
        reminder_time: null,
        reminder_date: null,
        reminder_message: null,
        reminder_consent: null,
      };
    },
    createNewPatient() {
      this.errors = [];
      this.loadForms();
      this.loadPackages();
      this.patient = this.getEmptyPatient();
      this.$modal.show("editPatientModal");
    },
    savePatient() {
      if (!this.validate()) {
        return false;
      }

      let data = {
        id: this.patient.id,
        first_name: this.patient.first_name,
        last_name: this.patient.last_name,
        national_id: this.patient.national_id,
        mobile: this.patient.mobile,
        patienttype:
          this.patient.patienttype !== "" ? this.patient.patienttype : null,
        organisation_id: this.isSuperAdmin
          ? this.patient.organisation_id
          : this.$store.getters.userData.organisationId,
        packages: this.patientpackages,
        forms: this.patientforms,
        reminder: this.patient.reminder,
        reminder_period: this.patient.reminder_period,
        reminder_day: this.patient.reminder_day,
        reminder_time: this.patient.reminder_time,
        reminder_date: this.patient.reminder_date,
        reminder_message: this.patient.reminder_message,
        reminder_consent: this.patient.reminder_consent,
      };

      this.$store
        .dispatch("addPatient", data)
        .then(() => {
          this.loadPatients();
          this.patient = this.getEmptyPatient();
          this.$modal.hide("editPatientModal");
          this.$store.dispatch("addPopUp", {
            type: "saved",
            message: "Ändringar sparade",
          });
        })
        .catch((error) => {
          this.errors.push({ label: error.response.data.message });
        });
    },
    validate() {
      this.errors.splice(0, this.errors.length);

      if (!this.patient.first_name) {
        this.errors.push({
          key: "first_name",
          label: "Förnamn måste fyllas i",
        });
      }
      if (!this.patient.last_name) {
        this.errors.push({
          key: "last_name",
          label: "Efternamn måste fyllas i",
        });
      }
      if (
        !this.patient.national_id ||
        !this.patient.national_id.length == 12 ||
        !this.patient.national_id.match(/^\d{12}$/)
      ) {
        this.errors.push({
          key: "national_id",
          label: "Personnummer måste anges i formatet ÅÅÅÅMMDDNNNN",
        });
      }

      this.validateReminders();

      return this.errors.length == 0 ? true : false;
    },
    validateReminders() {
      this.patientpackages.forEach((packag) => {
        if (packag.reminder) {
          let errorText =
            "Alla fält för påminnelser är inte ifyllda - " + packag.title;
          switch (packag.reminder_period) {
            case 1:
              if (
                packag.reminder_time == null ||
                packag.reminder_date == null ||
                packag.reminder_time == "" ||
                packag.reminder_date == ""
              ) {
                this.errors.push({
                  key: packag.title,
                  label: errorText,
                });
              }
              break;
            case 2:
              if (
                packag.reminder_time == null ||
                packag.reminder_end_date == null ||
                packag.reminder_time == "" ||
                packag.reminder_end_date == ""
              ) {
                this.errors.push({
                  key: packag.title,
                  label: errorText,
                });
              }
              break;
            case 3:
              if (
                packag.reminder_day == null ||
                packag.reminder_time == null ||
                packag.reminder_end_date == null ||
                packag.reminder_day == "" ||
                packag.reminder_time == "" ||
                packag.reminder_end_date == ""
              ) {
                this.errors.push({
                  key: packag.title,
                  label: errorText,
                });
              }
              break;
            case 4:
              if (
                packag.reminder_day == null ||
                packag.reminder_time == null ||
                packag.reminder_end_date == null ||
                packag.reminder_day == "" ||
                packag.reminder_time == "" ||
                packag.reminder_end_date == ""
              ) {
                this.errors.push({
                  key: packag.title,
                  label: errorText,
                });
              }
              break;
            default:
              if (packag.reminder_period == null) {
                this.errors.push({
                  key: packag.title,
                  label: errorText,
                });
              }
              break;
          }
        }
      });
    },
    packTitle(forms) {
      let text = "";
      forms.forEach((form, x) => {
        text += form.code;
        if (x < forms.length - 1) {
          text += ", ";
        }
      });
      return text;
    },
    chooseAllForms(e) {
      this.patientforms.forEach((d) => (d.checked = e.target.checked));
      this.handleSelectForm();
    },
    searchBySsn() {
      if (!this.validSsn) {
        alert("Ange ett komplett personnummer för att söka");
        return false;
      }

      this.$store
        .dispatch("searchBySsnAdmin", this.ssn)
        .then((response) => {
          let p = response;
          this.foundPatient = p;
          this.revealList = false;
        })
        .catch(() => {
          this.foundPatient = null;
        })
        .finally(() => {
          this.ssnSearchedFinished = true;
        });
    },
    reveal() {
      this.revealList = !this.revealList;
      if (this.revealList) {
        this.changePage(1);
        this.ssnSearchedFinished = false;
        this.foundPatient = null;
        this.ssn = null;
      }
    },
    changePage(pageNum) {
      this.$store.dispatch("adminChangePage", pageNum).then(() => {
        this.$store.dispatch("loadPatients");
      })
    },
    sortPatients(evt) {
      let col = evt.target.dataset.sort;
      this.$store.dispatch('adminSortPatients', col).then(() => {
        this.$store.dispatch('loadPatients');
      });
    },
    search: debounce(function() {
        this.$store.dispatch('adminSetSearchTerms', this.searchTerms)
          .then(() => {
            this.$store.dispatch("adminChangePage", 1).then(() => {
              this.$store.dispatch("loadPatients");
            })
          })
    }, 250),
    handleSelectForm() {
        let pack = this.patientpackages.find((a) => {
          return a.title == 'Självtest inför besök'
        });
        pack.forms = [];
        for (let index = 0; index < this.patientforms.length; index++) {
          const form = this.patientforms[index];
          if (form.checked) {
            pack.checked = true;
            pack.forms.push(form);
          }
        }

        if (pack.forms.length == 0) {
          pack.checked = false;
        }
    },
    changedPeriod(index) {
      this.patientpackages[index].reminder_date = null;
      this.patientpackages[index].reminder_day = null;
      this.patientpackages[index].reminder_end_date = null;
      this.patientpackages[index].reminder_time = null;
    }
  },
};
</script>
