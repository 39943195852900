export default {
    state: {
        sidebarOpen: false,
        sidebarShown: false,
    },
    mutations: {
        setSidebarShown (state) {
            state.sidebarShown = true
        },
        closeSidebar (state) {
            state.sidebarOpen = false
            state.sidebarShown = false
        },
        toggleSidebar (state) {
            state.sidebarOpen = !state.sidebarOpen
        },
    },
    actions: {
        setSidebarShown ({ commit }) {
            commit('setSidebarShown')
        },
        closeSidebar ({ commit }) {
            commit('closeSidebar')
        },
        toggleSidebar ({ commit }) {
            commit('toggleSidebar')
        },
    },
    getters: {
        isSidebarOpen: state => state.sidebarOpen,
        isSidebarShown: state => state.sidebarShown
    }
}