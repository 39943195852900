<template>
    <div>
        <div :id="'overview-chart-' + group + '-' + index"></div>
    </div>
</template>

<script>
import * as d3 from "d3";
import d3Tip from "d3-tip";

export default {
    name: 'OverviewGraph',
    mounted() {
        this.initGraph();
    },
    props: [
        "index",
        "group",
        "graph"
    ],
    watch: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        initGraph() {
            let data = this.graph.data.filter((d) => d.value == 0 || d.value == null ? false : true);
            let dataCount = data.length;
            let height = dataCount > 5 ? 400 : 300;
            let width = 600;
            let maxScore = this.graph.max ? this.graph.max : d3.max(data, (d) => d.value)
            let type = this.graph.type;
            let cutoffs = this.graph.cutoffs;
            let index = this.group + "-" + this.index;

            let margin = {
                top: 30,
                right: 30,
                left: 50,
                bottom: dataCount > 5 ? 150 : 50,
            };

            d3.tip = new d3Tip();

            if (type === 'vertical') {
                d3.select('#overview-chart-' + index).select("svg").remove();
                let svg = d3
                    .select("#overview-chart-" + index)
                    .append("svg")
                    .attr("class", "overview-chart")
                    .attr("width", width)
                    .attr("height", height);
    
                let x = d3
                    .scaleBand()
                    .domain(
                        data.map((d) => d.category)
                    )
                    .range([margin.left, width - margin.right])
                    .padding(0.1);
                
                let y = d3
                    .scaleLinear()
                    .domain([0, maxScore])
                    .nice()
                    .range([height - margin.bottom, margin.top]);
    
                let xAxis = (g) => 
                    g
                        .attr("transform", `translate(0, ${height - margin.bottom})`)
                        .call(d3.axisBottom(x).tickSizeOuter(0));
    
                let yAxis = (g) =>
                    g
                        .attr("transform", `translate(${margin.left}, 0)`)
                        .call(d3.axisLeft(y))
                        .call((g) => g.select(".domain").remove());
    
                let yAxisGrid = d3.axisLeft(y).tickSize(-(width - margin.left - margin.right)).tickFormat('').ticks(10);
    
                svg
                    .append('g')
                    .attr('class', 'y axis-grid')
                    .attr('transform', 'translate(' + margin.left + ', 0)')
                    .call(yAxisGrid);
    
                svg
                    .append("g")
                    .call(xAxis)
                    .selectAll("text")
                    .style("text-anchor", dataCount > 5 ? "end" : "mid")
                    .attr("dx", dataCount > 5 ? "-.8em" : "")
                    .attr("dy", dataCount > 5 ? ".15em" : "1em")
                    .attr("transform", () => {
                        return dataCount > 5 ? "rotate(-60)" : "rotate(0)";
                    })
                    .attr("font-size", "1.20em")
                    .attr("font-weight", "700");
    
                svg.append("g").call(yAxis);
    
                let rect = svg
                    .append("g")
                    .selectAll("rect")
                    .data(data)
                    .enter()
                    .append("rect");

                let tip = d3.tip
                    .attr("class", "tip")
                    .offset([-10, 0])
                    .html((d) => {
                        return (
                            "<p>" + d.category + ": " + d.value + "</p>"
                        );
                    });

                svg.call(tip);
    
                rect
                    .attr("x", (d) => x(d.category) + 5)
                    .attr("y", (d) => y(d.value))
                    .attr("width", x.bandwidth() - 10)
                    .attr("height", (d) => {
                        return y(0) - y(d.value)
                    })
                    .attr("class", "rect")
                    .attr("fill", (d) => {
                        for (let x = 0; x < cutoffs.length; x++) {
                            let c = cutoffs[x];
                            if (d.value <= c.value) {
                                return c.color;
                            }
                        }
                    })
                    .on("mouseover", tip.show)
                    .on("mouseout", tip.hide);

                let labels = this.graph.labels;

                if (labels) {
                    let label = svg
                        .append("g")
                        .attr("transform", () => {
                            return "translate(" + (margin.left) + ", 0)"
                        })
                        .selectAll("g")
                        .data(labels)
                        .enter()
                        .append("g")
                        .attr("transform", (d,i) => {
                            return "translate(" + (i * 125) + ", 0)"
                        });
    
                    label
                        .append("rect")
                        .attr("width", 15)
                        .attr("height", 15)
                        .attr("fill", (d) => {
                            return d.color
                        });
    
                    label
                        .append('text')
                        .text((d) => d.label)
                        .attr("transform", "translate(20, 14)");
                }
            } else {
                margin.left = 75;
                d3.select("#overview-chart-" + index).select("svg").remove();
                let svg = d3
                    .select("#overview-chart-" + index)
                    .append("svg")
                    .attr("class", "overview-chart")
                    .attr("width", width)
                    .attr("height", height);

                let x = d3
                    .scaleLinear()
                    .domain([0, maxScore])
                    .range([margin.left, width - margin.right]);

                let y = d3
                    .scaleBand()
                    .domain(
                        data.map((d) => d.category)
                    )
                    .range([height - margin.bottom, margin.top]);

                let xAxis = (g) => g.attr("transform", `translate(0, ${height - margin.bottom})`).call(d3.axisBottom(x).tickSizeOuter(0).tickValues([]));

                let yAxis = (g) => g.attr("transform", `translate(${margin.left}, 0)`).call(d3.axisLeft(y)).call((g) => g.select(".domain").remove());
                var labels = ["Låg risk", "Hög risk"];
                let xAxisGrid = d3.axisBottom(x).tickSize((height - margin.bottom - margin.top)).tickFormat((g) => labels[g]).ticks(maxScore);
    
                svg
                    .append('g')
                    .attr('class', 'x axis-grid')
                    .attr('transform', 'translate(0, ' + (margin.top) + ')')
                    .call(xAxisGrid)
                    .selectAll("text")
                    .attr("transform", "translate(" + ((width - margin.left - margin.right) / (maxScore*2)) + ", 10)")
                    .attr("font-size", "1.25em")
                    .attr("font-weight", "700");

                svg.append("g").call(xAxis);
                svg.append("g").call(yAxis);

                let circle = svg
                    .append("g")
                    .selectAll("circle")
                    .data(data)
                    .enter()
                    .append("circle");

                let tip = d3.tip
                    .attr("class", "tip")
                    .offset([-10, 0])
                    .html((d) => {
                        return (
                            "<p>" + d.category + "</p>"
                        );
                    });

                svg.call(tip);

                circle
                    .attr("cx", (d) => x(d.value))
                    .attr("cy", (d) => y(d.category) + y.bandwidth() - 15)
                    .attr("r", () => {
                        return (y.bandwidth() - 10) / 2
                    })
                    .attr("class", "circle")
                    .attr("fill", (d) => {
                        for (let x = 0; x < cutoffs.length; x++) {
                            let c = cutoffs[x];
                            if (d.value <= c.value) {
                                return c.color;
                            }
                        }
                    })
                    .on("mouseover", tip.show)
                    .on("mouseout", tip.hide);
            }

        }
    },
}
</script>

<style>
.axis-grid line {
  stroke: #ddd;
}
.rect:hover {
    fill-opacity: 60%;
}
.circle:hover {
    fill-opacity: 60%;
}
</style>