<template>
    <div class="sidebar fixed pin-t pin-l bg-white shadow-lg w-auto h-screen">
        <div class="ml-2 mr-4 px-2 flex flex-col h-full">
            <div class="flex-grow">
                <p class="text-grey text-xl pt-8 pb-4">{{ userFullName }}</p>

                <ul class="list-reset my-2 text-lg font-bold">
                    <!-- <li class="py-6"><router-link to="/list/referrals" class="block no-underline text-grey-darkest hover:text-grey">{{ $t('routes.referrals') }}</router-link></li> -->
                    <li class="py-6">
                        <router-link
                            to="/list/patients"
                            :class="isCurrentRoute('/list/patients')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.patients') }}</router-link>
                    </li>
                </ul>

                <ul class="list-reset my-2 text-lg font-bold">
                    <li v-if="isAdmin" class="py-6">
                        <router-link
                            to="/admin/organisations"
                            :class="isCurrentRoute('/admin/organisations')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.organisations') }}</router-link>
                    </li>
                    <li v-if="isAdmin || isLocalAdmin" class="py-6">
                        <router-link
                            to="/admin/users"
                            :class="isCurrentRoute('/admin/users')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.users') }}</router-link>
                    </li>
                    <li v-if="isAdmin || isLocalAdmin" class="py-6">
                        <router-link
                            to="/admin/patients"
                            :class="isCurrentRoute('/admin/patients')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.patients') }}</router-link>
                    </li>
                    <li v-if="isPacketManager" class="py-6">
                        <router-link
                            to="/admin/packages"
                            :class="isCurrentRoute('/admin/packages')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.packages') }}</router-link>
                    </li>
                    <li v-if="isSuperAdmin" class="py-6">
                        <router-link
                            to="/admin/forms"
                            :class="isCurrentRoute('/admin/forms')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.forms') }}</router-link>
                    </li>
                    <li v-if="isAdmin || isLocalAdmin" class="py-6">
                        <router-link
                            to="/admin/usagestats"
                            :class="isCurrentRoute('/admin/usagestats')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.usagestats') }}</router-link>
                    </li>
                    <li v-if="isDataExporter && isProduction" class="py-6">
                        <router-link
                            to="/admin/export-form-scores"
                            :class="isCurrentRoute('/admin/export-form-scores')"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.admin.exportformscores') }}</router-link>
                    </li>
                </ul>
            </div>

            <div class="text-center">
                <ul class="list-reset mb-16 text-lg">
                    <li class="pb-4 font-bold">
                        <router-link
                            to="/logout"
                            class="block no-underline text-grey-darkest hover:text-grey"
                        >{{ $t('routes.logout') }}</router-link>
                    </li>
                </ul>
            </div>

            <BaseFlatButton @click.native="close" type="left" class="mb-4">
                <path
                    d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"
                ></path>
                {{ $t('button.close') }}
            </BaseFlatButton>

            <div class="text-xs font-mono text-blue-light px-1 py-1">{{ version }}</div>
        </div>
    </div>
</template>

<script>
import { TweenMax, Power4 } from 'gsap'

export default {
    name: 'sidebar',
    mounted() {
        TweenMax.set(this.$el, {
            x: -this.$el.offsetWidth - 100
        })
    },
    computed: {
        version() {
            return process.APPLICATION_VERSION;
        },
        isAdmin() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false
            }
            return this.$store.getters.userData.roles.indexOf('ADMIN') > -1
        },
        isPacketManager() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false
            }
            return this.$store.getters.userData.roles.indexOf('PACKET') > -1
        },
        isLocalAdmin() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false
            }
            return this.$store.getters.userData.roles.indexOf('LOCALADMIN') > -1
        },
        isSuperAdmin() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false;
            }
            return this.$store.getters.userData.roles.indexOf('DEV') > -1
        },
        isDataExporter() {
            if (!this.$store.getters.userData || !this.$store.getters.userData.hasOwnProperty('roles')) {
                return false;
            }
            return this.$store.getters.userData.roles.indexOf('DATAEXPORTER') > -1
        },
        isProduction() {
            return process.env.NODE_ENV === "production"
        },
        userFullName() {
            return `${this.$store.getters.userData.firstname} ${this.$store.getters.userData.lastname}`
        },
        open() {
            return this.$store.getters.isSidebarOpen
        }
    },
    watch: {
        $route() {
            this.$store.dispatch('closeSidebar')
        },
        open: function (open) {
            const dX = open ? 0 : this.$el.offsetWidth + 100
            TweenMax.to(this.$el, 0.3, {
                x: -dX,
                ease: Power4.easeOut
            }).eventCallback('onComplete', () => {
                if (open) {
                    this.$store.dispatch('setSidebarShown')
                }
            })
        }
    },
    methods: {
        isCurrentRoute(to) {
            return this.$route.path === to ? 'text-blue' : ''
        },
        close() {
            this.$store.dispatch('closeSidebar')
        }
    }
}
</script>
