<template>
    <div class="bg-grey-lightest m-2 p-4 rounded-lg">
        <div v-if="edit">
            <div class="flex justify-end pb-2">
                <font-awesome-icon class="cursor-pointer" v-on:click="hide" icon="times"></font-awesome-icon>
            </div>
            <form action="" v-on:submit.prevent="savePackage" method="post">
                <div class="w-full flex">
                    <label class="self-center p-2 my-1" for="">Paketnamn: </label>
                    <input type="text" class="rounded-lg border border-grey-light p-2 w-full" v-model="pack.title">
                </div>
                <div class="flex">
                    <div class="flex-1 mr-2">
                        <select name="" multiple class="h-64 rounded-lg border border-grey-light p-2 w-full my-1">
                            <option disabled class="text-black p-2 -ml-2 -mr-2 -mt-2 mb-1 bg-green-lighter underline">Valda formulär</option>
                            <option v-for="(form, index) in pack.forms" :key="index" :value="form.id" v-on:dblclick="removeForm">{{ form.title }} - ({{ form.code }})</option>
                        </select>
                    </div>
                    <div class="flex-1 ml-2">
                        <select name="" multiple class="h-64 rounded-lg border border-grey-light p-2 w-full my-1">
                            <option disabled class="text-black p-2 -ml-2 -mr-2 -mt-2 mb-1 bg-blue-light underline">Tillgängliga formulär</option>
                            <option v-for="(form, index) in pack.availableForms" :key="index" :value="form.id" v-on:dblclick="addForm">{{ form.title }} - ({{ form.code }})</option>
                        </select>
                    </div>
                </div>
                <div class="flex justify-end">
                    <a v-if="saved" class="self-start">Sparat</a>
                    <button class="bg-green p-2 rounded-lg text-white text-lg mx-2">Spara</button>
                    <button type="button" class="bg-red p-2 rounded-lg text-white text-lg" @click="$emit('removePackage', pack)">Ta bort</button>
                </div>
            </form>
        </div>
        <div class="flex" v-else>
            <div class="text-lg w-full">{{ pack.title }}</div>
            <font-awesome-icon class="cursor-pointer" v-on:click="show" icon="chevron-down"></font-awesome-icon>
        </div>
    </div>
</template>
<script>
export default {
    name: "Packages",
    props: ['pack'],
    components: {
        
    },
    data() {
        return {
            errors: [],
            saved: false,
            edit: true
        }
    },
    mounted() {
        if(this.pack.id){
            this.edit = false;
        }
    },
    computed: {
        availableForms() {
            return this.pack.availableForms;
        },
        forms() {
            return this.pack.forms;
        },
        formIds() {
            let ids = [];
            this.pack.forms.forEach(p => {
                ids.push(p.id);
            });
            return ids;
        }
    },
    methods: {
        show() {
            this.edit = true;
        },
        hide() {
            this.edit = false;
        },
        removeForm(e)
        {
            let id = e.target.value;
            this.pack.availableForms.push(this.pack.forms.find((form) => {
                return form.id == id;
            }));
            this.pack.forms = this.pack.forms.filter((form) => {
                return form.id != id;
            });
        },
        addForm(e)
        {
            let id = e.target.value;
            this.pack.forms.push(this.pack.availableForms.find((form) => {
                return form.id == id;
            }));
            this.pack.availableForms = this.pack.availableForms.filter((form) => {
                return form.id != id;
            });
        },
        savePackage() {
            if (!this.validate()) {
                return false
            }

            let data = {
                'id': this.pack.id,
                'title': this.pack.title,
                'forms': this.formIds
            }

            if(this.pack.id){
                this.$store.dispatch("updatePackage", data).then(() => {
                    //RESPONSE
                    this.saved = true;
                }).catch((error) => {
                    this.saved = false;
                    alert(error.response.data.message);
                })
            }else{
                this.$store.dispatch("addPackage", data).then(() => {
                    //RESPONSE
                    this.saved = true;
                }).catch((error) => {
                    this.saved = false;
                    alert(error.response.data.message);
                })
            }
        },
        validate() {
            this.errors = [];

            if (!this.pack.title) {
                this.errors.push("Ett paketnamn måste anges")
            }
            if (this.forms.length < 1) {
                this.errors.push("Välj minst ett formulär till paketet")
            }
            let valid = this.errors.length == 0 ? true : false;
            if(!valid){
                alert(this.errors.join("\n"));
            }
            return valid;
        }
    },
}
</script>